
import {Component} from "react"
import Information from "src/components/general/Information"


class ContactUsPage extends Component<any> {

    render() {
        return (
            <Information title="Contact Us">
                <div id="contact-us">
                    Feedback or general queries are welcomed, contact us at jakemcnichollarchitect@gmail.com
                </div>
            </Information>
        )
    }
}

export default ContactUsPage
