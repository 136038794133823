import {Component} from "react"

class Header extends Component<any> {
    render() {
        return (
            <div>
                <div id="header" className="header">
                    <div className="website-and-logo-container">
                        <a href="/">
                            <img src="valterrain_website_title.svg" width="218.5px" height="60px"></img>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
