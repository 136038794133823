import {Component} from "react"

class Footer extends Component<any> {
    render() {
        return (
            <footer id="footer" className="footer">
                <div className="container">
                    <a className="footer-link" href="/?path=/contact">Contact Us</a>
                    <a className="footer-link" href="/?path=/terms">Terms of Use</a>
                </div>
            </footer>
        )
    }
}

export default Footer
