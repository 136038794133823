import {Component} from "react"


class Information extends Component<any> {
    className: string
    title: string

    constructor(props: any) {
        super(props)
        this.className = props.className
        this.title = props.title
    }

    render() {
        return (
            <div className="information-page">
                <div className="information-content">
                    <div>
                        <div className="information-title">
                            {this.title}
                        </div>
                        <div className="information-text">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Information
