import {Component} from "react"

import 'src/App.css';
import HouseValuationPage from "src/components/house_valuation/HouseValuationPage"
import LoginPage from "src/components/login/LoginPage"
import Config from "src/config/Config"
import ValuationAPIHelper from "src/utils/ValuationAPIHelper"
import Validator from "src/validation/Validator";
import Header from "src/components/layout/Header"
import Footer from "src/components/layout/Footer"
import PathRouter from "src/navigation/PathRouter"
import LoginHelper from "src/components/login/LoginHelper";
import ErrorHandler from "src/components/errors/ErrorHandler";
import Loader from "src/components/general/Loading";
import ContactUsPage from "./components/general/ContactUsPage";
import TermsOfUsePage from "./components/general/TermsOfUsePage";


class App extends Component {
    config: Config
    valuationAPIHelper: ValuationAPIHelper
    validator: Validator
    path: string 
    pathRouter: PathRouter
    loginHelper: LoginHelper
    errorHandler: ErrorHandler
    loader: Loader

    constructor(props: any) {
        super(props)
        this.config= new Config()
        this.valuationAPIHelper = this.config.getValuationAPIHelper()
        this.validator = this.config.getValidator()
        this.path = PathRouter.HOME_PATH
        this.pathRouter = this.config.getPathRouter()
        this.loginHelper = this.config.getLoginHelper()
        this.errorHandler = this.config.getErrorHandler()
        this.loader = this.config.getLoader()
    }

    render() {
        return (
            <div>
                <div className="body">
                    <Header/>
                    {this.getPage()}
                </div>
                <Footer/>
            </div>
        );
    }

    getPage() {
        let code = LoginHelper.getLoginCode()
        if (code) {
            this.path = PathRouter.LOGIN_PATH
            this.loginHelper.setCode(code)
            return (<LoginPage loginHelper={this.loginHelper} loader={this.loader}/>)
        }
        this.path = this.pathRouter.getRoute(this.path)
        console.log(this.path)
        if (this.path == PathRouter.LOGIN_PATH) {
            return (<LoginPage loginHelper={this.loginHelper} loader={this.loader}/>)
        }
        if (this.path == PathRouter.CONTACT_US_PATH) {
            return (<ContactUsPage></ContactUsPage>)
        }
        if (this.path == PathRouter.TERMS_OF_USE_PATH) {
            return (<TermsOfUsePage></TermsOfUsePage>)
        }
        return (
        <HouseValuationPage 
            valuationAPIHelper={this.valuationAPIHelper} 
            validator={this.validator}
            errorHandler={this.errorHandler}
            loader={this.loader}
        />)
    }
}

export default App;
