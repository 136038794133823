import {Component} from "react"
import Information from "src/components/general/Information"


class TermsOfUsePage extends Component<any> {

    render() {
        return (
            <Information title="Terms of Use">
                <div id="disclaimer">
                    The house valuation estimates provided are for informational purposes only and should not be considered as financial or professional advice.
                </div>
            </Information>
        )
    }
}

export default TermsOfUsePage